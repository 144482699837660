import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/mdx.js";
import { graphql } from "gatsby";
import CoreValues from "src/components/core-values";
import { TeamValuesImage } from "src/components/images";
import InvertedJumbotron from "src/components/inverted-jumbotron";
import LinkBanner from "src/components/link-banner";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageHeading = makeShortcode("PageHeading");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeading title={props.pageContext.frontmatter.title} image="working" crumbs={["TechSlice", props.pageContext.frontmatter.title]} mdxType="PageHeading"></PageHeading>
    <div className="container" style={{
      marginBottom: "2rem"
    }}>
      <p>{`TechSlice is a Software Product Agency. We work with innovators and entrepreneurs to bring their ideas into reality. Our full suite team is here to support every step of the product journey from fine tuning ideas to product and brand launch. While our main focus is on software platforms and integrations, we pull in expertise across Market Research, UX, Marketing, Branding, Strategy, Hardware, and Compliance. Our clients are our true partners in success.`}</p>
      <p>{`Our cost-plus-equity contract approach makes launching a startup more affordable and lowers technical barriers. You are backed up with a full service team and gain access to the talented resources you need, when you actually need them, without the extraordinary cost.`}</p>
      <p>{`We limit the number of products we work on at any given time so, when we do partner with you, you receive our undivided attention for the life cycle of your product. We are invested and focused on your success.`}</p>
    </div>
    <div className="container" style={{
      display: "flex",
      flexWrap: "wrap",
      columnGap: "4rem",
      marginBottom: "2rem"
    }}>
      <div style={{
        flexGrow: 1,
        width: "400px"
      }}>
        <h2 style={{
          color: "var(--strong)",
          fontSize: "1.9rem",
          fontWeight: 600
        }}>Our Core Values</h2>
        <CoreValues mdxType="CoreValues"></CoreValues>
      </div>
      <div style={{
        flexGrow: 1,
        width: "400px",
        marginTop: "2rem"
      }}>
        <TeamValuesImage mdxType="TeamValuesImage"></TeamValuesImage>
      </div>
    </div>
    <InvertedJumbotron mdxType="InvertedJumbotron">
      <div className="container" style={{
        paddingRight: "30%"
      }}>
        <h2>{`LET’S MOVE FORWARD, TOGETHER.`}</h2>
        <p>{`TechSlice comprises an elite group of design and development teams. Our model is scalable and focused on client needs. With extensive industry backgrounds in design, brand strategy, graphic design, programmatic development, and technical consultation, we bring tailored solutions to our projects from conception to product launch.`}</p>
        <p>{`Our team has its strong roots in mentorship and community. We are actively involved in our local Baltimore tech community and believe in a philosophy of achievement through continuous learning, growth, and teaching others.`}</p>
      </div>
    </InvertedJumbotron>
    <div className="container" style={{
      marginBottom: "4rem",
      marginTop: "4rem",
      textAlign: "center"
    }}>
      <h2 style={{
        color: "var(--primary)",
        fontSize: "1.9rem",
        fontWeight: 600
      }}>WHY OUR CLIENTS CHOOSE TECHSLICE</h2>
      <h3 style={{
        color: "var(--strong)"
      }}>Trust</h3>
A reliable organization with established credibility within the technology community.
      <h3 style={{
        color: "var(--strong)"
      }}>Access to Expert-class Developers</h3>
Your projects get done faster, saving you time, money, and headache.
      <h3 style={{
        color: "var(--strong)"
      }}>Project Management</h3>
More flexible on project delivery with expanded skillsets.
    </div>
    <LinkBanner header="Ready To Get Started?" paragraph="TechSlice makes starting a project simple. To get the ball rolling let’s setup a time to get to know one another." link="/contact" linkTitle="Send Us A Hello!" mdxType="LinkBanner"></LinkBanner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      